import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div({
  color: 'rgba(0,0,0,.87)',
  paddingBottom: '.421rem'
})

const Icon = styled.i({
  paddingRight: '.421rem',
  verticalAlign: 'bottom',
  boxSizing: 'content-box',
  width: '1.158rem'
})

const Category = styled.a({
  padding: '.421rem .842rem',
  lineHeight: 1,
  display: 'block',
  '&:hover': {
    background: 'rgba(0,0,0,.03)'
  }
})

const Categories = ({ categories = [], geo = false, base }) => (
  <Wrapper>
    {categories.map((item, index) => (
      <Category
        key={index}
        // href={`${base}?q=${item.name}&bus=1&only_cat=1&geo=${geo ? 1 : 0}`}
        href={`${base}?q=${item.name}&bus=1&only_cat=1`}
      >
        <Icon className={`icon-cat-${item.icon} ${item.color}`} />
        {item.name}
      </Category>
    ))}
  </Wrapper>
)

Categories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape),
  geo: PropTypes.bool,
  base: PropTypes.string.isRequired
}

export default Categories
