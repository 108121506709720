/* eslint-disable no-confusing-arrow, consistent-return */
import {
  AsYouType,
  format,
  isValidNumber,
  parse
} from 'libphonenumber-js/custom'

import { gettext } from 'django' // eslint-disable-line
import isEmail from 'validator/lib/isEmail'
import metadata from './metadata.min.json'

export const required = value =>
  value ? undefined : gettext('This field is required.')

export const maxLength = value => {
  return value
    ? value.length > 20
      ? gettext('max size invalid')
      : undefined
    : undefined
}

export const minLength = value => {
  return value
    ? value.length < 3
      ? gettext('min size invalid')
      : undefined
    : undefined
}

export const number = value => {
  return value
    ? isNaN(Number(value))
      ? gettext('number invalid')
      : undefined
    : undefined
}

export const minNumber = value => {
  return value < 10 ? gettext('min number invalid') : undefined
}
export const maxNumber = value => {
  return value > 50 ? gettext('max number invalid') : undefined
}
export const email = value => {
  return value
    ? isEmail(value.toString())
      ? undefined
      : gettext('no email')
    : gettext('no email')
}
export const isZipCode = value => {
  return value
    ? value.length === 4
      ? undefined
      : gettext('Postcode invalid')
    : undefined
}

export const trimValue = value => value && value.trim()
export const stripNumbers = value => value && value.replace(/\d+/g, '')
export const stripCommas = value =>
  value && value.replace(/(^,)|(^ ,)|(,$)|(, $)/g, '')
export const zipCode = value => value && value.substring(0, 4)
export const streetName = value => {
  let cleanedName = stripNumbers(value)
  cleanedName = stripCommas(cleanedName)
  return value && cleanedName
}

const getCoutryChoice = (field, values) =>
  values && values.country && values.country[field]
    ? values.country[field]
    : 'CH'

export const phoneNumberFormat = (field, value, allvalues) => {
  const country = getCoutryChoice(field, allvalues)
  if (value) {
    return new AsYouType(country, metadata).input(value)
  }
  return ''
}

export const phoneNumberValidate = (
  field,
  value,
  allvalues = { values: {} }
) => {
  const country = getCoutryChoice(field, allvalues.values)
  if (value) {
    try {
      return isValidNumber(parse(value, country, metadata), metadata)
        ? undefined
        : gettext('number invalid')
    } catch (err) {
      return gettext('number invalid')
    }
  } else {
    return undefined
  }
}

// Format a number from Lichtenstein in Local.ch style, for consistency
// Internaional with 00 instead of +
export const formatLiNumber = value => {
  try {
    const parsed = format(
      { phone: value, country: 'LI' },
      'INTERNATIONAL',
      metadata
    )
    return parsed.replace(/^\+/, '00')
  } catch (err) {
    return value
  }
}

// remove internationnalisation if present for swiss
export const formatChNumber = value => {
  try {
    const parsed = format(parse(value, 'CH', metadata), 'NATIONAL', metadata)
    return parsed
  } catch (err) {
    return value
  }
}

export const formatPhone = (value, pre, allvalues) =>
  phoneNumberFormat('phone', value, allvalues)
export const formatMobile = (value, pre, allvalues) =>
  phoneNumberFormat('natel', value, allvalues)

export const validatePhone = (value, pre, allvalues) =>
  phoneNumberValidate('phone', value, allvalues)
export const validateMobile = (value, pre, allvalues) =>
  phoneNumberValidate('natel', value, allvalues)

export const formatNumberIntl = (value, country) =>
  format(parse(value, country, metadata), 'INTERNATIONAL', metadata)

export const phone = {
  formatPhone,
  formatMobile,
  validatePhone,
  validateMobile,
  formatNumberIntl,
  formatLiNumber,
  formatChNumber
}
