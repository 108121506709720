import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '.421rem .842rem',
    lineHeight: 1,
    cursor: 'pointer'
  },
  ({ isActive, isSelected }) => {
    const styles = []
    if (isActive) {
      styles.push({
        color: 'rgba(0,0,0,.95)',
        background: 'rgba(0,0,0,.03)'
      })
    }
    if (isSelected) {
      styles.push({
        color: 'rgb(216,35,42)',
        fontWeight: '700'
      })
    }
    return styles
  }
)

const ItemIcon = styled.i({
  paddingRight: '.421rem',
  width: '1.158rem',
  lineHeight: 1,
  fontSize: '0.8rem',
  boxSizing: 'content-box',
  flex: '0 0 auto'
})

const Item = styled.span({
  whiteSpace: 'nowrap',
  wordWrap: 'normal',
  overflow: 'hidden',
  width: '100%',
  padding: 'auto'
})

const ListItem = ({ itemProps, item, title, icon }) => {
  return (
    <Wrapper {...itemProps}>
      <ItemIcon className={icon} />
      <Item>{title}</Item>
    </Wrapper>
  )
}

ListItem.propTypes = {
  itemProps: PropTypes.shape(),
  item: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  icon: PropTypes.string,
  title: PropTypes.string
}

export default ListItem
