import LoaderIcon from 'app/components/loader'
import PropTypes from 'prop-types'
import React from 'react'
import { gettext } from 'django' // eslint-disable-line
import { isDesktop } from 'app/utils/mobile-detector'
import styled from '@emotion/styled'

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '.421rem 0rem',
  height: '60px'
})

const Input = styled.input(
  {
    height: '100%',
    width: '100%',
    fontSize: '19px',
    wordWrap: 'break-word',
    lineHeight: '2.0em',
    outline: 0,
    whiteSpace: 'normal',
    minHeight: '2.0em',
    background: '#fff',
    display: 'inline-block',
    padding: '0 1em 0 1em',
    color: 'rgba(0,0,0,.87)',
    border: '1px solid rgb(227,227,227)',
    borderRadius: '26px',
    boxshadow: 'none !important',
    appearance: 'none'
  },
  ({ isOpen, isExpanded }) =>
    isExpanded
      ? {
        border: 'none',
        boxShadow: 'none',
        padding: '0',
        borderRadius: 0
      }
      : null
)
const BackButton = styled.button({
  padding: '0 .421rem 0 0',
  width: '1.158rem',
  boxSizing: 'content-box',
  flex: '0 0 auto',
  color: 'rgb(81, 75, 75)',
  marginTop: '0.1rem'
})

const SearchButton = styled.button(
  {
    background: '#514b4b',
    borderRadius: '16px',
    width: '32px',
    height: '32px',
    fontSize: '.9em',
    padding: '.1em',
    color: 'white',
    flex: '0 0 auto'
  },
  ({ isOpen, isExpanded }) =>
    !isExpanded
      ? !isDesktop.ie()
        ? {
          position: 'absolute',
          right: '0.2rem'
        }
        : {
          position: 'relative',
          right: '2.0rem'
        }
      : {
        marginRight: '0.6rem'
      }
)

const EraserButton = styled.button(
  {
    right: '2.4em !important',
    top: '.4em'
  },
  ({ value }) => (value !== '' ? { display: 'block' } : null)
)

const Header = ({
  inputProps,
  isExpanded,
  loading = false,
  onBack,
  onClear,
  onSubmit
}) => (
  <Wrapper>
    {isExpanded && (
      <BackButton
        className='btn icon-previous'
        onClick={onBack}
        aria-label={gettext('back')}
      />
    )}
    <Input
      {...inputProps}
      isExpanded={isExpanded}
      aria-label={gettext('aria_search')}
      autoComplete='off'
    />
    <EraserButton
      className='btn eraser'
      value={inputProps.value}
      onClick={onClear}
      aria-label={gettext('search.clear')}
    >
      <i className='icon-cross' />
    </EraserButton>
    {loading ? (
      <LoaderIcon size='32px' strokeWidth={5} marginRight='0.6rem' />
    ) : (
      <SearchButton
        className='btn'
        onClick={onSubmit}
        aria-label={gettext('search.label')}
        isExpanded={isExpanded}
      >
        <i className='icon-custom-magnifier' />
      </SearchButton>
    )}
  </Wrapper>
)

Header.propTypes = {
  inputProps: PropTypes.shape(),
  isExpanded: PropTypes.bool,
  loading: PropTypes.bool,
  onBack: PropTypes.func,
  onClear: PropTypes.func,
  onClick: PropTypes.func,
  onSubmit: PropTypes.func
}

export default Header
