import PropTypes from 'prop-types'
import React from 'react'

const LoaderIcon = ({ size = '55px', strokeWidth = 6 }) => (
  <div
    className='loadingIcon'
    style={{
      textAlign: 'center',
      lineHeight: '1',
      marginRight: '0.6em'
    }}
  >
    <svg
      className='spinner'
      width={size}
      height={size}
      viewBox='0 0 66 66'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        className='circle'
        fill='none'
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        cx='33'
        cy='33'
        r='30'
      />
    </svg>
  </div>
)

LoaderIcon.propTypes = {
  size: PropTypes.string,
  strokeWidth: PropTypes.number
}

export default LoaderIcon
