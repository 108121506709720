import features from 'app/utils/features'
import { isMobile } from 'app/utils/mobile-detector'
import routes from 'app/utils/routes'

if (routes.entryDetail) {
  import(/* webpackChunkName: "openings" */
  /* webpackMode: "eager" */
    'features/openings/detail').then(m => new m.Openings())
}

if (routes.entryDetail && features.weatherWidget) {
  import(/* webpackChunkName: "weatherWidget" */ 'features/weather-widget').then(
    m => m.initialize()
  )
}

if (features.mapsApi) {
  import(/* webpackChunkName: "maps" */ 'features/maps')
}

if (features.maps) {
  import(/* webpackChunkName: "maps" */ 'features/maps/layout')
}

if ((routes.home || routes.entryDetail) && features.slides) {
  import(/* webpackChunkName: "slider" */ 'features/slider')
}

if (routes.home && isMobile.any()) {
  import(/* webpackChunkName: "home-gradient" */ 'features/home-gradient')
}

if (features.gallery) {
  import(/* webpackChunkName: "galley" */ 'features/gallery')
}

if (features.header) {
  import(/* webpackChunkName: "back-navigation" */ 'features/back-navigation')
}

if (features.lazyimages) {
  import(/* webpackChunkName: "lazyimages" */ 'lazysizes').then(lazySizes => {
    lazySizes.init()
  })
}

if (features.accordion) {
  import(/* webpackChunkName: "accordion" */ 'features/accordion')
}
if (features.tooltip) {
  import(/* webpackChunkName: "tooltip" */ 'features/tooltip')
}
if (features.dfp) {
  import(/* webpackChunkName: "ads" */ 'features/ads')
}

if (routes.entriesList) {
  import(/* webpackChunkName: "infinitescroll" */ 'features/infinitescroll')
}

if (routes.ads) {
  import(/* webpackChunkName: "landing-ads" */ 'features/landing-ads')
}

if (routes.edit) {
  import(/* webpackChunkName: "edit" */ 'features/edit')
}

if (routes.create) {
  import(/* webpackChunkName: "create" */ 'features/create')
}

if (routes.validation) {
  import(/* webpackChunkName: "validation" */ 'features/validation')
}

if (routes.weather) {
  import(/* webpackChunkName: "weather" */ 'features/weather')
}

if (routes.claim) {
  import(/* webpackChunkName: "claim" */ 'features/claim')
}

if (routes.confirmClaim) {
  import(/* webpackChunkName: "call" */ 'features/call')
}

if (routes.signup) {
  import(/* webpackChunkName: "signup" */ 'features/signup')
}

if (routes.offers) {
  import(/* webpackChunkName: "offers" */ 'features/offers')
}

if (routes.claimPartner) {
  import(/* webpackChunkName: "claim-partner" */ 'features/claim-partner')
}
if (routes.accounts) {
  import(/* webpackChunkName: "accounts-places" */ 'features/accounts')
}

if (features.searchForm) {
  import(/* webpackChunkName: "search-chunk" */
    'features/search').then(m => {
    m.render()
  })
}
