import 'core-js/fn/promise'

import React, { Component } from 'react'
import { debounce, search } from './../actions/search'

// import Cookies from 'js-cookie'
import Downshift from 'downshift'
import Main from './main'
import PropTypes from 'prop-types'
// import { fetchPosition } from 'features/geolocation/position'
import { isMobile } from 'app/utils/mobile-detector'
import scrollToComponent from 'react-scroll-to-component'

class Container extends Component {
  constructor (props) {
    super(props)
    this.state = {
      inputValue: props.input,
      items: [],
      itemsCount: null,
      isExpanded: false,
      defaultIsOpen: false,
      geo: false,
      error: false,
      fetchingPosition: false,
      allowed: true,
      canton: props.canton,
      searchcontext: props.searchcontext,
      ...props.initialState
    }
    this.debounceSearch = debounce(this.doSearch, 200)
  }

  componentDidMount () {
    /*
    try {
      const itemGeoSession = JSON.parse(sessionStorage.getItem('geo'))
      if (!itemGeoSession) {
        fetchPosition()
          .then(position => {
            Cookies.set('user_location', {
              lat: position.next.coords.latitude,
              lng: position.next.coords.longitude
            })
            this.setState({
              geo: true
            })
            sessionStorage.setItem('geo', true)
          })
          .catch(e => {
            this.setState({
              geo: false
            })
            sessionStorage.setItem('geo', false)
          })
      } else {
        this.setState({
          geo: JSON.parse(sessionStorage.getItem('geo'))
        })
      }
    } catch (e) {
      this.setState({
        geo: false
      })
    }
    */
  }

  itemToString = item => {
    if (item !== null) {
      if (typeof item === 'string') {
        return item.full_name ? String(item.full_name) : String(item)
      } else {
        return String(item.name)
      }
    }
    return ''
  }

  handleSelect = selectedItem => {
    if (selectedItem && selectedItem.slug && selectedItem.short_id) {
      window.location = `/${selectedItem.slug}${selectedItem.short_id}`
      this.setState({
        loading: true,
        inputValue: this.itemToString(selectedItem)
      })
    } else {
      if (typeof selectedItem !== 'string') {
        window.location = selectedItem.url
        this.setState({
          loading: true,
          inputValue: this.itemToString(selectedItem)
        })
      } else {
        let inputTapped = this.state.inputValue
        if (inputTapped) {
          if (inputTapped.slice(-1) !== ' ') {
            inputTapped = this.state.inputValue.substring(
              0,
              this.state.inputValue.lastIndexOf(' ') + 1
            )
          }
        }
        this.setState({
          loading: false,
          inputValue: (inputTapped += this.itemToString(selectedItem))
        })
        this.doSearch(inputTapped)
      }
    }
  }
  handleOuterClick = () => {
    this.setState({ isExpanded: false, loading: false })
  }
  handleUserAction = changes => {
    this.setState(({ inputValue }) => {
      if (changes.type === Downshift.stateChangeTypes.keyDownEscape) {
        inputValue = ''
      }
      return { inputValue }
    })
  }

  handleChange = e => {
    const value = e.target.value
    if (!value) {
      this.setState({
        inputValue: '',
        items: [],
        itemsCount: null,
        loading: false,
        error: false
      })
      return
    } else {
      this.setState({
        isExpanded: true
      })
    }

    this.debounceSearch(value)
    this.setState({ inputValue: value })
  }

  handleBack = e => {
    e.preventDefault()
    this.setState({
      items: [],
      isExpanded: false
    })
  }

  handleClickInput = changes => {
    if (isMobile.phone()) {
      scrollToComponent(changes.target, {
        offset: -5,
        align: 'top',
        duration: 500
      })
    }
    this.setState(({ items, itemsCount, inputValue, isExpanded }) => {
      if (!isExpanded) {
        if (inputValue.length === 0) {
          items = []
          itemsCount = null
          inputValue = ''
        }
        isExpanded = true
      }
      return { items, itemsCount, inputValue, isExpanded }
    })
  }

  handleKeyDown = e => {
    if (e.keyCode === 13) {
      this.doResultSubmit()
    }
  }

  doResultSubmit = () => {
    if (this.state.inputValue && this.state.inputValue.length > 0) {
      // const urllocal = `${this.props.base}?q=${this.state.inputValue}&geo=${
      //  this.state.geo ? 1 : 0
      // }`
      const urllocal = `${this.props.base}?q=${encodeURIComponent(
        this.state.inputValue
      )}`
      window.location.href = urllocal
    }
  }

  handleClear = e => {
    e.preventDefault()
    this.setState({
      items: [],
      itemsCount: null,
      inputValue: ''
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.doResultSubmit()
  }

  doSearch = (value, geo) => {
    search(
      this,
      value,
      this.state.geo,
      this.state.canton,
      this.state.searchcontext
    )
  }

  noResult = () =>
    this.state.itemsCount === 0 &&
    this.state.inputValue &&
    this.state.inputValue.length !== 0

  render () {
    return (
      <Main
        onUserAction={this.handleUserAction}
        onChange={this.handleChange}
        onClick={this.handleClickInput}
        onKeyDown={this.handleKeyDown}
        onSelect={this.handleSelect}
        onBack={this.handleBack}
        onClear={this.handleClear}
        noResult={this.noResult}
        onSubmit={this.handleSubmit}
        itemToString={this.itemToString}
        onOuterClick={this.handleOuterClick}
        {...this.props}
        {...this.state}
      />
    )
  }
}

Container.propTypes = {
  initialState: PropTypes.shape(),
  placeholder: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape).isRequired,
  base: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  canton: PropTypes.string,
  input: PropTypes.string,
  searchcontext: PropTypes.string
}

export default Container
