import Cookies from 'js-cookie'
import { isMobile } from 'app/utils/mobile-detector'
import jQuery from 'jquery'
require('bootstrap-sass/assets/javascripts/bootstrap/modal')
;($ => {
  $('body').on('click', '.js-modal-toggle', function onClickToggle () {
    $(`#${$(this).data('modal')}`).modal()
  })
})(jQuery)

// Text too long toggles
const openLong = el => {
  el.querySelector('.long-text').classList.add('full')
  el.querySelector('.btn-long').style.opacity = 0
}

if (document.body.contains(document.querySelector('.closing-box .close-box'))) {
  document
    .querySelector('.closing-box .close-box')
    .addEventListener('click', () => {
      const box = document
        .querySelector('.closing-box .close-box')
        .closest('.closing-box')
      document.querySelector(
        '.closing-box .close-box'
      ).parentNode.style.opacity = 0
      document
        .querySelector('.closing-box .close-box')
        .parentNode.addEventListener('transitionend', () => {
          document.querySelector(
            '.closing-box .close-box'
          ).parentNode.style.display = 'none'
        })
      Cookies.set(box.dataset.name, 'Yes', {
        expires: Number(box.dataset.duration)
      })
    })
}
Array.from(document.querySelectorAll('.closing-box')).forEach(closingBox => {
  if (Cookies.get(closingBox.dataset.name) !== 'Yes') {
    closingBox.style.opacity = 1
    closingBox.style.display = 'block'
  }
})

if (Cookies.get('claim_close') !== 'Yes') {
  if (document.body.contains(document.querySelector('.claim-box'))) {
    document.querySelector('.claim-box').style.opacity = 1
  }
}

if (document.body.contains(document.querySelector('.claim-box .close-claim'))) {
  document
    .querySelector('.claim-box .close-claim')
    .addEventListener('click', () => {
      document.querySelector(
        '.claim-box .close-claim'
      ).parentNode.style.opacity = 0
      Cookies.set('claim_close', 'Yes', { expires: 20 })
    })
}

if (Cookies.get('banned_close') !== 'Yes') {
  if (document.body.contains(document.querySelector('.banned'))) {
    document.querySelector('.banned').style.height = document.querySelector(
      '.banned'
    ).style.maxHeight
    document.querySelector('.banned').style.height = document.querySelector(
      '.banned'
    ).style.display = 'block'
  }
}

if (document.body.contains(document.querySelector('.banned .close'))) {
  document.querySelector('.banner .close').addEventListener('click', () => {
    document.querySelector('.banner .close').style.opacity = 0
    document
      .querySelector('.banner .close')
      .addEventListener('transitionend', () => {
        document.querySelector('.banner .close').style.display = 'none'
      })
    Cookies.set('banned_close', 'Yes', { expires: 1 })
  })
}

if (Cookies.get('banner_close') !== 'Yes') {
  if (document.body.contains(document.querySelector('.banner'))) {
    document.querySelector('.banner').style.height = document.querySelector(
      '.banner'
    ).style.maxHeight
  }
}

if (document.body.contains(document.querySelector('.banner .close'))) {
  document
    .querySelector('.banner .close-parent')
    .addEventListener('click', () => {
      document.querySelector('.banner .close').parentNode.style.opacity = 0
      document
        .querySelector('.banner .close')
        .parentNode.addEventListener('transitionend', () => {
          document.querySelector('.banner .close').parentNode.style.display =
            'none'
        })
      Cookies.set('banner_close', 'Yes', { expires: 7 })
    })
}

if (document.body.contains(document.querySelector('.banner .close-parent'))) {
  document
    .querySelector('.banner .close-parent')
    .addEventListener('click', () => {
      document.querySelector(
        '.banner .close-parent'
      ).parentNode.style.opacity = 0
      document
        .querySelector('.banner .close-parent')
        .parentNode.addEventListener('transitionend', () => {
          document.querySelector(
            '.banner .close-parent'
          ).parentNode.style.display = 'none'
        })
    })
}

if (document.body.contains(document.querySelector('.alert-message'))) {
  document
    .querySelector('.alert-message .icon-delete')
    .addEventListener('click', () => {
      document
        .querySelector('.alert-message .icon-delete')
        .closest('.alert-message').style.opacity = 0
      document
        .querySelector('.alert-message .icon-delete')
        .closest('.alert-message')
        .addEventListener('transitionend', () => {
          document
            .querySelector('.alert-message .icon-delete')
            .closest('.alert-message').style.display = 'none'
        })
    })
  setTimeout(() => {
    document.querySelector('.alert-message').style.opacity = 0
    document
      .querySelector('.alert-message')
      .addEventListener('transitionend', () => {
        document.querySelector('.alert-message').style.display = 'none'
      })
  }, 3000)
}

if (
  document.body.contains(
    document.querySelector('.js-header .language-switcher')
  )
) {
  document.querySelector('.js-header .language-switcher').addEventListener(
    'touchstart',
    e => {
      document
        .querySelector('.js-header .language-switcher')
        .querySelector('.active a')
        .removeAttribute('href')
      e.stopPropagation()
      if (
        document
          .querySelector('.js-header .language-switcher')
          .classList.contains('hover')
      ) {
        setTimeout(() => {
          document
            .querySelector('.js-header .language-switcher ul')
            .classList.remove('hover')
        }, 150)
      } else {
        document
          .querySelector('.js-header .language-switcher')
          .classList.add('hover')
        return false
      }
      return false
    },
    { passive: true }
  )

  document
    .querySelector('.js-header .language-switcher')
    .addEventListener('mouseover', () => {
      document
        .querySelector('.js-header .language-switcher')
        .classList.add('hover')
    })

  document
    .querySelector('.js-header .language-switcher')
    .addEventListener('mouseleave', () => {
      document
        .querySelector('.js-header .language-switcher')
        .classList.remove('hover')
    })
}

if (document.body.contains(document.querySelector('.btn-sub-menu .sub-menu'))) {
  document.querySelector('.btn-sub-menu').addEventListener('mouseover', () => {
    document
      .querySelector('.btn-sub-menu')
      .querySelector('.sub-menu')
      .classList.add('hover')
  })

  document.querySelector('.btn-sub-menu').addEventListener('mouseleave', () => {
    document
      .querySelector('.btn-sub-menu')
      .querySelector('.sub-menu')
      .classList.remove('hover')
  })
}

if (document.body.contains(document.querySelector('.js-header .js-menu-btn'))) {
  document
    .querySelector('.js-header .js-menu-btn')
    .addEventListener('click', () => {
      document.querySelector('html').classList.toggle('m-active')
      if (isMobile.any()) {
        document
          .querySelector('.master')
          .insertAdjacentHTML(
            'afterbegin',
            '<div class="modal-backdrop in m-close"></div>'
          )
      }
    })
}
const event = isMobile.apple() ? 'touchstart' : 'click'
Array.from(
  document.querySelectorAll('.close-menu, .modal-backdrop.m-close')
).forEach(child =>
  child.addEventListener(event, () => {
    if (document.querySelector('html').classList.contains('m-active')) {
      if (isMobile.any()) {
        document
          .querySelector('.modal-backdrop.m-close')
          .parentNode.removeChild(
            document.querySelector('.modal-backdrop.m-close')
          )
      }
    }
    document.querySelector('html').classList.toggle('m-active')
  })
)

if (document.body.contains(document.querySelector('.long-text'))) {
  Array.from(document.querySelectorAll('.long-text')).forEach(long => {
    const maxHeight = long.dataset.height
    const button =
      '<div class="btn-long btn-icon btn-round btn-big green"><span class="icon-arrow-down"></div></div>'
    if (window.outerHeight >= maxHeight) {
      long.style.maxHeight = `${maxHeight}px`
      long.insertAdjacentHTML('afterend', button)
      long.classList.add('blur')
    }
  })
  Array.from(document.querySelectorAll('.btn-long')).forEach(buttonLong => {
    buttonLong.addEventListener('click', () => {
      openLong(buttonLong.parentNode)
    })
  })
}

if (document.body.contains(document.querySelector('.card.hours .btn-long'))) {
  document
    .querySelector('.card.hours .btn-long')
    .addEventListener('click', () => {
      document
        .querySelector('.card.hours .btn-long')
        .parentNode.querySelector('.long-text')
        .querySelector('.today').innerHTML = document
          .querySelector('.card.hours .btn-long')
          .parentNode.querySelector('.long-text')
          .querySelector('.today').dataset.day
      document
        .querySelector('.card.hours .btn-long')
        .parentNode.querySelector('.long-text')
        .querySelector('.today')
        .parentNode.classList.add('active')
    })
}

if (document.body.contains(document.querySelector('.loader_btn'))) {
  const submitButton = document.querySelector('[name="submit"]')
  const previewButton = document.querySelector('[name="preview"]')

  previewButton.addEventListener('click', () => {
    if (!previewButton.classList.contains('loading')) {
      previewButton.classList.add('loading')
      previewButton.innerHTML =
        '<div class="loader"></div>' + previewButton.innerHTML
      submitButton.style.visibility = 'hidden'
    }
  })

  submitButton.addEventListener('click', () => {
    if (!submitButton.classList.contains('loading')) {
      previewButton.style.visibility = 'hidden'
      submitButton.classList.add('loading')
      submitButton.innerHTML =
        '<div class="loader"></div>' + submitButton.innerHTML
    }
  })
}
export default openLong
