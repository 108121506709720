import fetch from 'so-fetch-js'
import { gettext } from 'django' // eslint-disable-line
import { lang } from 'app/utils/lang'

const suggestEndpoint = `/${lang()}/autocomplete/?q=`
const instantEndPoint = `/${lang()}/instants/?q=`
const faqEndPoint = `/${lang()}/faq/autocomplete/?q=`

const fetchInstant = (value, canton = null, geo = null) =>
  fetch(
    instantEndPoint +
      encodeURIComponent(value) +
      // (canton ? `&canton=${canton}` : '') +    decommenter une fois requette terminer
      // (geo ? `&geo=${0}` : ''),
      (geo ? '' : ''),
    {
      credentials: 'same-origin'
    }
  )
    .then(response => response.data.result.instants)
    .catch()

const fetchSuggests = (value, canton = null, geo = null) =>
  fetch(suggestEndpoint + encodeURIComponent(value), {
    credentials: 'same-origin'
  })
    .then(response => response.data.result.autocompletes)
    .catch()
// error => {
// throw new Error(error.statusText)
// }

const fetchSuggestsFAQ = value =>
  fetch(faqEndPoint + encodeURIComponent(value), {
    credentials: 'same-origin'
  })
    .then(response => response.data.result.autocompletes)
    .catch()
// error => {
// throw new Error(error.statusText)
// }

export const search = async (
  parent,
  value,
  geo,
  canton = null,
  searchcontext
) => {
  if (!value) {
    return
  }
  parent.setState({ loading: true })
  try {
    let entries = ''
    if (searchcontext === 'entries') {
      entries = await fetchSuggests(value, canton, geo)
    } else {
      entries = await fetchSuggestsFAQ(value)
    }
    const instants =
      searchcontext === 'entries' ? await fetchInstant(value, canton, geo) : ''

    entries = entries.slice(0, 5)
    const itemsCount = entries.length + instants.length

    parent.setState({
      items: [
        {
          type: 'entries',
          title: gettext('search.suggests'),
          icon: 'icon-custom-magnifier',
          data: entries
        },
        {
          type: 'entries',
          title: gettext('search.entries'),
          data: instants
        }
      ],
      loading: false,
      itemsCount: itemsCount
    })
  } catch (err) {
    parent.setState({ loading: false, items: [], error: true })
  }
}

export function debounce (fn, wait) {
  let timeout
  return function () {
    clearTimeout(timeout)
    timeout = setTimeout(() => fn.apply(this, arguments), wait || 1)
  }
}
