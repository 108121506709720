const documentClasses = document.documentElement.classList

export default {
  entryDetail: documentClasses.contains('page--entries-detail'),
  entriesList: documentClasses.contains('page--entries-list'),
  home: documentClasses.contains('page--home'),
  claim: documentClasses.contains('claim'),
  confirmClaim: documentClasses.contains('confirm-claim'),
  signup: documentClasses.contains('signup'),
  claimPartner: documentClasses.contains('claim-partner'),
  ads: documentClasses.contains('ads_landing'),
  edit: documentClasses.contains('edit'),
  create: documentClasses.contains('create'),
  validation: documentClasses.contains('validation'),
  weather: documentClasses.contains('page--weather'),
  offers: documentClasses.contains('offers'),
  accounts: documentClasses.contains('user')
}
