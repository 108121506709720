import * as valid from 'app/utils/validate'
import { gettext } from 'django'

if (window.Element && !Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    const matches = (this.document || this.ownerDocument).querySelectorAll(s)
    let i
    let el = this
    do {
      i = matches.length
      while (--i >= 0 && matches.item(i) !== el) {}
    } while (i < 0 && (el = el.parentElement))
    return el
  }
}

function onBlur (e) {
  let error
  const parentNode = this.closest('.field-wrapper.required')

  if (this.getAttribute('type') === 'email') {
    const validatemail = valid.email(this.value)
    error = validatemail !== null ? validatemail : undefined
  } else if (this.classList.contains('input-tel')) {
    const validatePhone = valid.phone.validatePhone(this.value, '', '')
    error = validatePhone !== undefined ? validatePhone : undefined
  } else if (!this.value && this.value !== undefined) {
    const required = valid.required(this.value)
    error =
      required !== undefined
        ? this.getAttribute('type') === 'password'
          ? gettext('no password')
          : required
        : undefined
  }

  if (error !== undefined) {
    parentNode.classList.add('field_error')
    if (!parentNode.querySelectorAll('.error_message').length) {
      const el = document.createElement('div')
      const domstring = `<ul class="error_message field"><li>${error}</li></ul>`
      el.innerHTML = domstring
      parentNode.appendChild(el.firstChild)
    }
  }
}

function clickIcon (e) {
  if (
    document.body.contains(
      this.closest('.field-wrapper').querySelector('input')
    )
  ) {
    this.closest('.field-wrapper')
      .querySelector('input')
      .focus()
  }
}

function focus (e) {
  const parentNode = this.closest('.field-wrapper.required')
  if (parentNode.classList.contains('field_error')) {
    parentNode.classList.remove('field_error')
    if (parentNode.querySelectorAll('.error_message').length) {
      for (const message of parentNode.querySelectorAll('.error_message')) {
        parentNode.removeChild(message)
      }
    }
  }
}

const field = Array.from(document.querySelectorAll('.field-wrapper.required'))

if (field.length) {
  field.forEach(parent => {
    let childInput = ''
    if (
      parent.querySelector('input') !== null &&
      parent.querySelector('input') !== 'undefined'
    ) {
      childInput = parent.querySelector('input')
    } else if (
      parent.querySelector('select') !== null &&
      parent.querySelector('select') !== 'undefined'
    ) {
      childInput = parent.querySelector('select')
    } else if (
      parent.querySelector('textarea') !== null &&
      parent.querySelector('textarea') !== 'undefined'
    ) {
      childInput = parent.querySelector('textarea')
    }
    childInput.addEventListener('blur', onBlur)
    childInput.addEventListener('focus', focus)
  })
}

Array.from(document.querySelectorAll('.icon-edit.icon-form')).forEach(finded =>
  finded.addEventListener('click', clickIcon)
)
