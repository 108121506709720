const isMobile = {
  phone () {
    return navigator.userAgent.match(
      /Android.+mobile|BlackBerry|iPhone|iPod|Opera Mini|IEMobile/i
    )
  },
  apple () {
    return (
      navigator.userAgent.match(/iPhone|iPod|iPad/i) &&
      !navigator.userAgent.match(/IEMobile/i)
    )
  },
  windows () {
    return navigator.userAgent.match(/IEMobile/i)
  },
  tablet () {
    return (
      navigator.userAgent.match(/\b(iPad)|\b(Android)/i) && !isMobile.phone()
    )
  },
  any () {
    return isMobile.phone() || isMobile.tablet()
  }
}

const isDesktop = {
  xl () {
    if (matchMedia('(min-width: 1500px)').matches) {
      return true
    }
    return false
  },
  sm () {
    if (
      matchMedia('(max-width: 1499px)').matches &&
      matchMedia('(min-width: 1023px)').matches
    ) {
      return true
    }
    return false
  },
  firefox () {
    if (navigator.userAgent.match(/Firefox/i)) {
      return true
    }
    return false
  },
  ie () {
    if (navigator.userAgent.match(/Trident/i)) {
      return true
    }
    return false
  },
  any () {
    if (matchMedia('(min-width: 1023px)').matches) {
      return true
    }
    return false
  }
}

export { isMobile, isDesktop }
