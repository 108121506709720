import 'core-js/fn/promise'

import Container from './components/container'
import React from 'react'
import ReactDOM from 'react-dom'

export const render = () => {
  const root = document.getElementById('search-form')
  const root2 = document.getElementById('search-form2')
  let categories = []

  try {
    categories = JSON.parse(root.dataset.categories)
  } catch (e) {
    // carry on
  }

  ReactDOM.render(
    <Container
      categories={categories}
      placeholder={root.dataset.placeholder}
      base={root.dataset.base}
      formId={root.parentNode.id}
      canton={root.dataset.canton ? root.dataset.canton : null}
      input={root.dataset.input ? root.dataset.input : ''}
      searchcontext={
        root.dataset.searchcontext ? root.dataset.searchcontext : 'entries'
      }
    />,
    root
  )
  if (root2) {
    ReactDOM.render(
      <Container
        categories={categories}
        placeholder={root2.dataset.placeholder}
        base={root2.dataset.base}
        formId={root2.parentNode.id}
        canton={root2.dataset.canton ? root2.dataset.canton : null}
        input={root.dataset.input ? root.dataset.input : ''}
        searchcontext={
          root2.dataset.searchcontext ? root2.dataset.searchcontext : 'entries'
        }
      />,
      root2
    )
  }
}

/*
8px => 0.421rem
16px => 0.842rem
24px => 1.263rem
32px => 1.684rem
*/
