import { Info, Menu, SeparatedLine, Wrapper } from './main-elements'

import Categories from './categories'
import Downshift from 'downshift'
import Header from './header'
import ListItem from './list-item'
import PropTypes from 'prop-types'
import React from 'react'
import { gettext } from 'django' // eslint-disable-line

const MyDiv = ({ innerRef, ...rest }) => <Wrapper ref={innerRef} {...rest} />

const Main = ({
  items,

  isExpanded,
  inputValue,
  defaultIsOpen,

  onUserAction,
  onChange,
  onClick,
  onSelect,
  onBack,
  onClear,
  onKeyDown,
  onSubmit,
  onOuterClick,

  error,
  placeholder,
  categories,
  base,
  geo,
  loading,
  searchcontext,

  itemsCount,
  noResult,
  itemToString
}) => {
  return (
    <Downshift
      isOpen={isExpanded}
      inputValue={inputValue}
      defaultIsOpen={defaultIsOpen}
      onSelect={onSelect}
      onUserAction={onUserAction}
      itemToString={itemToString}
      onOuterClick={onOuterClick}
    >
      {({
        selectedItem,
        getRootProps,
        getInputProps,
        getItemProps,
        highlightedIndex,
        isOpen
      }) => {
        return (
          <MyDiv
            {...getRootProps({ refKey: 'innerRef' })}
            isExpanded={isExpanded}
          >
            <Header
              inputProps={{
                ...getInputProps({
                  isOpen,
                  name: 'q',
                  placeholder: placeholder,
                  onChange: onChange,
                  onClick: onClick,
                  onKeyDown: onKeyDown
                })
              }}
              isExpanded={isExpanded}
              loading={loading}
              onBack={onBack}
              onClear={onClear}
              onSubmit={onSubmit}
            />
            {isOpen && (
              <Menu>
                <SeparatedLine />
                {noResult() && !loading && !error && (
                  <Info>{gettext('search.no-results')}</Info>
                )}
                {error && (
                  <Info className='alert-message error'>
                    {gettext('search.error')}
                  </Info>
                )}
                {((searchcontext === 'entries' && itemsCount === 0) ||
                  (searchcontext === 'entries' && !itemsCount)) && (
                  <Categories categories={categories} base={base} geo={geo} />
                )}
                {
                  items.reduce(
                    (result, section, sectionIndex) => {
                      if (section.data.length >= 1) {
                        result.sections.push(
                          <div key={sectionIndex}>
                            {section.data.map((item, itemIndex) => {
                              const index = result.itemIndex++
                              if (typeof item === 'string') {
                                return (
                                  <ListItem
                                    key={itemIndex}
                                    itemProps={{
                                      ...getItemProps({
                                        item,
                                        index: index,
                                        isActive: highlightedIndex === index,
                                        isSelected: selectedItem === item
                                      })
                                    }}
                                    item={item}
                                    title={item}
                                    icon={section.icon}
                                  />
                                )
                              } else {
                                return (
                                  <ListItem
                                    key={itemIndex}
                                    itemProps={{
                                      ...getItemProps({
                                        item,
                                        index: index,
                                        isActive: highlightedIndex === index,
                                        isSelected: selectedItem === item
                                      })
                                    }}
                                    item={item.name}
                                    title={item.name}
                                    icon={'icon-cat-' + item.category_icon.name}
                                  />
                                )
                              }
                            })}
                          </div>
                        )
                      }
                      return result
                    },
                    { sections: [], itemIndex: 0 }
                  ).sections
                }
              </Menu>
            )}
          </MyDiv>
        )
      }}
    </Downshift>
  )
}

Main.propTypes = {
  items: PropTypes.array,

  isExpanded: PropTypes.bool,
  inputValue: PropTypes.string,
  defaultIsOpen: PropTypes.bool,

  onUserAction: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  onBack: PropTypes.func,
  onClear: PropTypes.func,
  onKeyDown: PropTypes.func,
  onSubmit: PropTypes.func,
  onOuterClick: PropTypes.func,

  error: PropTypes.bool,
  placeholder: PropTypes.string,
  categories: PropTypes.array,
  base: PropTypes.string,
  geo: PropTypes.bool,
  loading: PropTypes.bool,
  searchcontext: PropTypes.string,

  itemsCount: PropTypes.number,
  noResult: PropTypes.func,
  itemToString: PropTypes.func
}
MyDiv.propTypes = {
  innerRef: PropTypes.func
}
export default Main
