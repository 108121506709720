const printWithoutMapLinks = Array.from(
  document.querySelectorAll('a[href="#print-without-map"]')
)
const printWithMapLinks = Array.from(
  document.querySelectorAll('a[href="#print-with-map"]')
)
const map = document.querySelector('#print-image')
const iconFullScreen = document.querySelector('.icon-fullscreen')

const print = (e, withMap) => {
  if (e !== undefined) {
    e.preventDefault()
  }

  map.style.display = withMap === true ? 'block' : 'none'
  iconFullScreen.style.display = 'none'

  window.print()
}

for (const printWithoutMapLink of printWithoutMapLinks) {
  printWithoutMapLink.addEventListener('click', e => {
    print(e, false)
  })
}

for (const printWithMapLink of printWithMapLinks) {
  printWithMapLink.addEventListener('click', e => {
    print(e, true)
  })
}
