import { gettext } from 'django' // eslint-disable-line

class Openings {
  constructor (options) {
    this.isOpen = false
    this.marker = 'default'
    this.statuses = {
      open: {
        text: gettext('Open'),
        className: 'green',
        marker: 'open'
      },
      close: {
        text: gettext('Closed'),
        className: 'orange',
        marker: 'close'
      }
    }
    this.setup()
  }

  setup () {
    const openings = document.getElementsByClassName('js-opening')

    const openDay = day => !!day.className.match('opened')
    this.isOpen = [...openings].filter(openDay).length >= 1

    if (openings.length) {
      this.showStatus()
      this.setMarker()
    }
  }

  setMarker () {
    document.querySelector(
      '[data-map-marker-icon]'
    ).dataset.mapMarkerIcon = this.isOpen
      ? this.statuses.open.marker
      : this.statuses.close.marker
  }

  showStatus () {
    const container = document.createElement('span')
    const statusClass = this.isOpen
      ? this.statuses.open.className
      : this.statuses.close.className

    container.className = `hours-icon pull-right ${statusClass}`
    container.textContent = this.isOpen
      ? this.statuses.open.text
      : this.statuses.close.text

    const labels = document.getElementsByClassName('js-opening-label')
    ;[...labels].forEach(label => label.appendChild(container.cloneNode(true)))
  }
}

export { Openings }
