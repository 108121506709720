const isVisible = el =>
  el ? window.getComputedStyle(el).display !== 'none' : false

const notify = (key, name, status) => {
  document.documentElement.dataset[key] = status
  document.documentElement.dispatchEvent(
    new CustomEvent(`${key}${name}`, {
      detail: status
    })
  )
}

export { isVisible, notify }
