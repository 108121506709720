import styled from '@emotion/styled'

// inspired by https://codesandbox.io/s/zx1kj58npl
const MenuSectionTitle = styled.h3({
  color: 'rgb(81, 75, 75)',
  fontWeight: 'bold',
  background: 'rgb(249, 249, 249)',
  borderTop: 'solid 1px rgb(227,227,227)',
  borderBottom: 'solid 1px rgb(227,227,227)',
  padding: '.421rem .842rem',
  fontSize: '1.055rem',
  lineHeight: 1
})

const Menu = styled.div({
  maxHeight: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  background: 'white'
})

const Info = styled.div({
  padding: '.421rem .842rem',
  borderBottom: 'solid 1px rgb(227,227,227)',
  fontSize: '.85rem',
  lineHeight: 1.2
})
const SeparatedLine = styled.div({
  color: 'white',
  borderBottom: '1px solid rgb(227,227,227)'
})

const Wrapper = styled.div(
  {
    fontSize: '1rem',
    background: 'rgba(0, 0, 0, 0)',
    minHeight: '36px',
    transformOrigin: '50% 50%',
    color: 'rgb(81, 75, 75)',
    lineHeight: '1'
  },
  props => {
    if (props.isExpanded) {
      return {
        position: 'relative',
        width: '100%',
        background: 'white',
        padding: '0',
        zIndex: 9,
        boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.3)'
      }
    }
  }
)

export { Info, Menu, MenuSectionTitle, SeparatedLine, Wrapper }
