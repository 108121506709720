;(function standaloneiPhone (document, navigator, standalone) {
  // prevents links from apps from oppening in mobile safari
  // this javascript must be the first script in your <head>
  if (standalone in navigator && navigator[standalone]) {
    let curnode
    const location = document.location
    const stop = /^(a|html)$/i
    document.addEventListener(
      'click',
      e => {
        curnode = e.target
        while (!stop.test(curnode.nodeName)) {
          curnode = curnode.parentNode
        }
        // Condidions to do this only on links to your own app
        // if you want all links, use if('href' in curnode) instead.
        const chref = curnode.href
        if (
          'href' in curnode && // is a link
          chref.replace(location.href, '').indexOf('#') && // is not an anchor
          (!/^[a-z+.-]+:/i.test(chref) || // either does not have a proper scheme (relative links)
            chref.indexOf(`${location.protocol}//${location.host}`) === 0) // or is in the same protocol and domain
        ) {
          e.preventDefault()
          location.href = curnode.href
        }
      },
      false
    )
  }
})(document, window.navigator, 'standalone')
