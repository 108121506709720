import { isVisible } from 'app/utils/dom-helpers'

const documentClasses = document.documentElement.classList

export default {
  position: documentClasses.contains('use--position'),
  mapsApi: documentClasses.contains('use--maps-api'),
  maps: documentClasses.contains('page--map-layout'),
  displayMap: isVisible(document.querySelector('.google-map-section')),
  entryDetail: documentClasses.contains('page--entries-detail'),
  lazyimages: document.querySelectorAll('.lazyload').length >= 1,
  slides:
    document.querySelectorAll('.slider').length &&
    document.querySelectorAll('.js_multislides').length,
  gallery: document.querySelectorAll('.my-gallery').length >= 1,
  accordion: document.querySelectorAll('.accordeon').length >= 1,
  weatherWidget: document.getElementById('weather-widget-container'),
  tooltip: document.querySelectorAll('[data-toggle="tooltip"]').length >= 1,
  dfp: document.querySelectorAll('.advertising.dfp').length >= 1,
  searchForm: document.querySelector('#search-form'),
  header: document.getElementById('back-navigation')
}
